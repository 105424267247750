class City {
	id!: number

	name!: string

	postalCode!: string

	constructor(id: number) {
		this.id = id
	}
}

export default City
