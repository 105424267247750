import ReactSelectOption from "../../../api/models/reactSelectOption"

export const SIZE: number = 10
export const PAGE: number = 0
export const ROWS_OPTIONS: Array<number> = [10, 20, 50]

export const TYPE_OPTIONS: ReactSelectOption[] = [
	{ label: "Salons", value: "Salons" },
	{ label: "Forums", value: "Forums" },
	{ label: "Job dating", value: "Job dating" },
	{ label: "Autres", value: "Autres" },
]
