import placeApprentissage from "../../resources/images/place-apprentissage.png"
import unJeuneUneSolution from "../../resources/images/un-jeune-une-solution.jpg"

export const SIZE: number = 20
export const PAGE: number = 0

export const IMAGES: { src: string; url: string; title: string }[] = [
	{
		src: placeApprentissage,
		url: "https://www.pass.fonction-publique.gouv.fr/",
		title: "Place de l'apprentissage",
	},
	{
		src: unJeuneUneSolution,
		url: "https://www.1jeune1solution.gouv.fr/stages",
		title: "Un Jeune, Une Solution",
	},
]
