import {
	People as PeopleIcon,
	Visibility as EyeIcon,
	Work as WorkIcon,
	Send as SendIcon,
	School as SchoolIcon,
	Apartment as ApartmentIcon,
} from "@mui/icons-material"

export const USERS_TITLE: string = "Inscriptions"
export const USERS_ICON: any = PeopleIcon
export const USERS_COLOR: string = "primary.main"

export const APPLYINGS_TITLE: string = "Démarches effectuées"
export const APPLYINGS_ICON: any = SendIcon
export const APPLYINGS_COLOR: string = "primary.main"

export const VISITS_TITLE: string = "Visites"
export const VISITS_ICON: any = EyeIcon
export const VISITS_COLOR: string = "primary.main"

export const OFFERS_TITLE: string = "Offres de stages"
export const OFFERS_ICON: any = WorkIcon
export const OFFERS_COLOR: string = "primary.main"

export const LEARN_WORKER_TITLE: string = "Alternants"
export const LEARN_WORKER_ICON: any = SchoolIcon
export const LEARN_WORKER_COLOR: string = "primary.main"

export const TRAINEE_TITLE: string = "Stagiaires"
export const TRAINEE_ICON: any = ApartmentIcon
export const TRAINEE_COLOR: string = "primary.main"
